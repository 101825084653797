var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c("custom-loading-planning", {
            attrs: { loading: _vm.isLoading, label: "Project fimalac data" },
          }),
          _c(
            "b-card",
            { staticClass: "card-border-blue-light", attrs: { "no-body": "" } },
            [
              _c(
                "b-row",
                { staticClass: "p-2" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex flex-row gap-4",
                      attrs: { cols: "8" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-grow-1" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "filterInput-project-fimalac",
                              placeholder: _vm.FormMSG(
                                4,
                                "Enter project name to search"
                              ),
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleSearch.apply(null, arguments)
                              },
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                          _c(
                            "b-tooltip",
                            {
                              attrs: {
                                target: "filterInput-project-fimalac",
                                placement: "top",
                                triggers: "focus",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(
                                      353,
                                      "You must type more than 3 characters followed by enter to activate the search"
                                    )
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-grow-0" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass:
                                "cursor-pointer d-inline-flex justify-content-center align-items-center",
                              staticStyle: {
                                border: "1px solid #00a09c",
                                "border-radius": "4px",
                              },
                              style: `width: 100px`,
                              attrs: {
                                variant: "none",
                                title: "Start to search",
                              },
                              on: { click: _vm.handleSearch },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "pl-2",
                                  staticStyle: { color: "#00a09c" },
                                },
                                [_vm._v(_vm._s(_vm.FormMSG(5, "Search")))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex flex-row align-items-center justify-content-end gap-4",
                      attrs: { cols: "4" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: { "border-radius": "4px !important" },
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleRefresh.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                            },
                            [
                              _c(_vm.getLucideIcon("RefreshCcw"), {
                                tag: "component",
                                attrs: {
                                  color: "#00A09C",
                                  size: 18,
                                  "stroke-width": 2.25,
                                },
                              }),
                              _c("div", { staticClass: "pl-2" }, [
                                _vm._v(_vm._s(_vm.FormMSG(6, "Refresh"))),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "p-2 m-2 bg-beige" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-row w-100 p-1" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-start w-25",
                              },
                              [_vm._v(_vm._s(_vm.FormMSG(20, "Artist")))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-start w-25",
                              },
                              [_vm._v(_vm._s(_vm.FormMSG(8, "Production")))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-start w-25",
                              },
                              [_vm._v(_vm._s(_vm.FormMSG(9, "Projet name")))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-end w-25",
                              },
                              [_vm._v(_vm._s(_vm.FormMSG(10, "Option")))]
                            ),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "p-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.projects.length === 0
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-center w-100 text-muted fs-14 p-2",
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(11, "No project active")
                                    ) +
                                    "\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex flex-column",
                                staticStyle: {
                                  "max-height": "650px",
                                  "overflow-y": "scroll",
                                },
                                attrs: { id: "list-of-content" },
                              },
                              _vm._l(_vm.projects, function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center w-100 p-1 hover-active",
                                      staticStyle: {
                                        "border-bottom": "1px solid #e2e6eb",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-start w-25",
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-start w-25",
                                        },
                                        [_vm._v(_vm._s(item.production))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-start w-25",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.projectHierarchyName)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-end w-25",
                                        },
                                        [
                                          item.exist
                                            ? _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.left.html",
                                                      value: `${_vm.FormMSG(
                                                        16,
                                                        "Edit"
                                                      )}`,
                                                      expression:
                                                        "`${FormMSG(16, 'Edit')}`",
                                                      modifiers: {
                                                        hover: true,
                                                        left: true,
                                                        html: true,
                                                      },
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    "border-radius":
                                                      "4px !important",
                                                    border:
                                                      "1px solid #225cbd !important",
                                                  },
                                                  attrs: {
                                                    variant: "outline-dark",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.handleOpenModal(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-content-center align-items-center",
                                                    },
                                                    [
                                                      _c(
                                                        "svg",
                                                        {
                                                          staticClass:
                                                            "lucide lucide-arrow-big-down-dash",
                                                          attrs: {
                                                            xmlns:
                                                              "http://www.w3.org/2000/svg",
                                                            width: "18",
                                                            height: "18",
                                                            color: "#225CBD",
                                                            viewBox:
                                                              "0 0 24 24",
                                                            fill: "none",
                                                            stroke:
                                                              "currentColor",
                                                            "stroke-width": "2",
                                                            "stroke-linecap":
                                                              "round",
                                                            "stroke-linejoin":
                                                              "round",
                                                          },
                                                        },
                                                        [
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M15 5H9",
                                                            },
                                                          }),
                                                          _c("path", {
                                                            attrs: {
                                                              d: "M15 9v3h4l-7 7-7-7h4V9h6z",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-tooltip",
                                                      rawName:
                                                        "v-b-tooltip.hover.left.html",
                                                      value: `${_vm.FormMSG(
                                                        17,
                                                        "Add"
                                                      )}`,
                                                      expression:
                                                        "`${FormMSG(17, 'Add')}`",
                                                      modifiers: {
                                                        hover: true,
                                                        left: true,
                                                        html: true,
                                                      },
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    "border-radius":
                                                      "4px !important",
                                                    border:
                                                      "1px solid #47c7bf !important",
                                                  },
                                                  attrs: {
                                                    variant: "outline-dark",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.handleOpenModal(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-content-center align-items-center",
                                                    },
                                                    [
                                                      _c(
                                                        _vm.getLucideIcon(
                                                          "Plus"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: {
                                                            color: "#47C7BF",
                                                            size: 18,
                                                            "stroke-width": 2.25,
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ],
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    !_vm._isActiveSearch
                      ? _c("div", { staticClass: "end-of-list p-2" }, [
                          _vm._v(_vm._s(_vm.sizeOfUsers())),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  staticClass: "modal-success",
                  attrs: {
                    "header-class": "header-class-modal-doc-package",
                    title: "Would you like to add this project",
                    size: "md",
                    "no-close-on-backdrop": "",
                    "ok-variant": "success",
                    "ok-only": "",
                    "modal-class": "mui-animation",
                    fade: false,
                  },
                  on: { ok: _vm.hideModal },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-footer",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            { staticClass: "w-100" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "float-left",
                                  attrs: {
                                    variant: "outline-dark",
                                    size: "sm",
                                  },
                                  on: { click: _vm.hideModal },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.FormMSG(12, "Close")) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "float-right",
                                  attrs: {
                                    variant: "outline-primary",
                                    size: "sm",
                                    disabled:
                                      _vm.nameOfProject.length !== 0
                                        ? false
                                        : true,
                                  },
                                  on: { click: _vm.handleAddProject },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.action) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.open,
                    callback: function ($$v) {
                      _vm.open = $$v
                    },
                    expression: "open",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "container-mobile animated fadeIn" },
                    [
                      _c(
                        "div",
                        { staticClass: "content-detailsMyTimesheet-mobile" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "nameOfProject-api",
                              readonly: true,
                              placeholder: "Enter your project to search",
                            },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleAddProject.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                            model: {
                              value: _vm.nameOfProject,
                              callback: function ($$v) {
                                _vm.nameOfProject = $$v
                              },
                              expression: "nameOfProject",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }